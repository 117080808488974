import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  Typography,
  LinearProgress,
  Grid,
  Box,
  Stack,
  Divider,
  List,
  ListItem,
  ListItemContent,
  ListItemButton,
  ListItemDecorator,
  Chip,
} from "@mui/joy";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  LabelList,
} from "recharts";
import { differenceInMonths } from "date-fns";
import { collection, onSnapshot, getFirestore } from "firebase/firestore";
import { Person } from "@mui/icons-material";
import { upperFirstLetterWord } from "../modules/utils";

const Overview = () => {
  const [stats, setStats] = useState();
  const [participants, setParticipants] = useState();
  const navigate = useNavigate();

  const prepareArtData = (artData) => {
    const nameMap = {
      i_have_been_trying_naturally: "Natural",
      "i_have_been_undergoing_ivf_(frozen_embryo_transfer_cycle)":
        "IVF (Frozen)",
      "i_have_been_undergoing_ovulation_induction_(medications_to_assist_with_ovulation)_plus_artificial_insemination_(intrauterine_insemination)":
        "Ovulation + AI",
      "i_have_been_undergoing_artificial_insemination_(intrauterine_insemination)_in_a_natural_cycle":
        "AI (Natural)",
      "i_have_been_undergoing_ivf_(stimulation_cycle_plus_fresh_embryo_transfer)":
        "IVF (Fresh)",
      "i_have_been_undergoing_ovulation_induction_(medications_to_assist_with_ovulation)_only":
        "Ovulation Only",
    };

    return Object.keys(artData).map((key) => ({
      name: nameMap[key] || key, // Use mapped short name or original if not found
      fullName: key,
      value: artData[key],
    }));
  };

  const prepareStatusData = (statusData) => {
    return Object.keys(statusData).map((key) => ({
      name: key,
      fullName: key,
      value: statusData[key],
    }));
  };

  // calculate some stats from the participant data
  useEffect(() => {
    const unsubscribe = onSnapshot(
      collection(getFirestore(), "participants"),
      (querySnapshot) => {
        let totalTryingToGetPregnant = 0;
        let totalPregnant = 0;
        let tyringToConceiveAndHadSurgery = 0;
        let tyringToConceiveAndHaveNotHadSurgery = 0;
        let totalInitialSurveysDone = 0;
        let totalSurveysCompleted = 0;
        let art = {};
        let statuses = {};
        let totalParticipants = 0;
        let totalBirths = 0;
        const studyNameTotals = { ELFS: 0, "MISs-ENDO": 0 };
        const languageTotals = {};
        const inactivityCounts = {
          "1 month": 0,
          "2 months": 0,
          "3 months": 0,
          "More than 3 months": 0,
        };
        const medicalStats = {
          tubalPatency: 0,
          semenAnalysis: 0,
          hadSurgery: 0,
        };

        let allParticipants = {};
        for (const participant of querySnapshot.docs) {
          // Skip if participant is marked as tester
          if (participant.data().tester === true) continue;

          totalParticipants++;
          allParticipants[participant.id] = participant.data();
          const {
            netResponses: {
              been_trying_pregnant,
              ever_had_endometriosis_surgery,
              how_trying_get_pregnant,
              are_you_pregnant,
              outcome_recent_pregnancy,
              totalSurveys,
              endometriosis_surgery_date,
              tubal_patency_result,
              semen_analysis_results,
            },
            studyName,
            language,
            status,
            studyStatus,
          } = participant.data();

          if (totalSurveys > 0) {
            totalInitialSurveysDone++;
          }

          if (endometriosis_surgery_date) {
            medicalStats.hadSurgery++;
          }

          if (tubal_patency_result) {
            medicalStats.tubalPatency++;
          }

          if (semen_analysis_results) {
            medicalStats.semenAnalysis++;
          }

          if (status === "active" && studyStatus?.lastSurveyCompletionDate) {
            const monthsInactive = differenceInMonths(
              new Date(),
              studyStatus.lastSurveyCompletionDate.toDate(),
            );

            if (monthsInactive === 1) {
              inactivityCounts["1 month"]++;
            } else if (monthsInactive === 2) {
              inactivityCounts["2 months"]++;
            } else if (monthsInactive === 3) {
              inactivityCounts["3 months"]++;
            } else if (monthsInactive > 3) {
              inactivityCounts["More than 3 months"]++;
            }
          }

          totalSurveysCompleted += totalSurveys;

          // ELFS or ms-endo
          studyNameTotals[studyName]++;

          if (!languageTotals[language]) {
            languageTotals[language] = 1;
          } else {
            languageTotals[language]++;
          }

          if (!statuses[status]) {
            statuses[status] = 1;
          } else {
            statuses[status]++;
          }

          if (outcome_recent_pregnancy === "i_had_a_live_birth") {
            totalBirths++;
          } else if (are_you_pregnant) {
            totalPregnant++;
          } else if (been_trying_pregnant) {
            totalTryingToGetPregnant++;
            if (ever_had_endometriosis_surgery) {
              tyringToConceiveAndHadSurgery++;
            }
            if (how_trying_get_pregnant) {
              if (!art[how_trying_get_pregnant]) {
                art[how_trying_get_pregnant] = 1;
              } else {
                art[how_trying_get_pregnant] += 1;
              }
            }
          } else if (ever_had_endometriosis_surgery) {
            tyringToConceiveAndHaveNotHadSurgery++;
          }
        }

        setParticipants(allParticipants);

        setStats({
          totalTryingToGetPregnant,
          totalParticipants,
          tyringToConceiveAndHadSurgery,
          tyringToConceiveAndHaveNotHadSurgery,
          art,
          totalPregnant,
          totalBirths,
          studyNameTotals,
          languageTotals,
          totalInitialSurveysDone,
          totalSurveysCompleted,
          statuses,
          inactivityCounts,
          medicalStats,
        });
      },
      (error) => {
        console.error("Error fetching participants:", error);
      },
    );

    return () => unsubscribe(); // Cleanup subscription on unmount
  }, []);

  if (!stats) {
    return <LinearProgress />;
  }

  const artData = prepareArtData(stats.art);
  const statusData = prepareStatusData(stats.statuses);
  const inactivityData = Object.entries(stats.inactivityCounts).map(
    ([name, value]) => ({
      name,
      value,
    }),
  );
  const COLORS = [
    "var(--joy-palette-success-300)",
    "var(--joy-palette-danger-300)",
    "var(--joy-palette-warning-300)",
    "var(--joy-palette-neutral-300)",
  ];

  console.log(stats);

  return (
    <Grid container justifyContent="space-evenly" spacing={3}>
      <Grid xs={12}>
        <Typography level="h2" gutterBottom>
          Stats
        </Typography>
        <Divider />
      </Grid>
      <Grid xs={12} lg={6} xl={3}>
        <Stack spacing={3}>
          <Card variant="solid" color="success" invertedColors>
            <CardContent>
              <Typography level="body-md">Total pregnant</Typography>
              <Typography level="h2" gutterBottom>
                {stats.totalPregnant}
              </Typography>
              <Typography level="body-md">Total births</Typography>
              <Typography level="h2" gutterBottom>
                {stats.totalBirths}
              </Typography>
            </CardContent>
          </Card>
          <Card variant="solid" color="primary" invertedColors>
            <CardContent>
              <Typography level="body-md">Total participants</Typography>
              <Typography level="h2" gutterBottom>
                {stats.totalParticipants}
              </Typography>
              <Typography level="body-md">Total in ELFS study</Typography>
              <Typography level="h2" gutterBottom>
                {stats.studyNameTotals["ELFS"]}
              </Typography>
              <Typography level="body-md">Total in MISs-ENDO study</Typography>
              <Typography level="h2" gutterBottom>
                {stats.studyNameTotals["MISs-ENDO"]}
              </Typography>
            </CardContent>
          </Card>
          <Card variant="solid" color="primary" invertedColors>
            <CardContent>
              <Typography level="body-md">Total surveys completed</Typography>
              <Typography level="h2" gutterBottom>
                {stats.totalSurveysCompleted}
              </Typography>
              <Typography level="body-md">
                Total participants who completed at least one survey
              </Typography>
              <Typography level="h2" gutterBottom>
                {stats.totalInitialSurveysDone}
              </Typography>
            </CardContent>
          </Card>
          <Card variant="solid" color="primary" invertedColors>
            <CardContent>
              <Typography level="h5" gutterBottom>
                Survey Inactivity
              </Typography>
              <Typography level="body-sm">
                Total participants by months since last survey
              </Typography>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  layout="vertical"
                  data={inactivityData}
                  margin={{
                    right: 55,
                  }}
                >
                  <XAxis type="number" hide />
                  <YAxis
                    dataKey="name"
                    type="category"
                    axisLine={false}
                    tick={{ fill: "var(--joy-palette-text-primary)" }}
                    width={111}
                  />
                  <Bar dataKey="value" fill="var(--joy-palette-primary-400)">
                    {inactivityData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                    <LabelList dataKey="value" position="right" />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Stack>
      </Grid>
      <Grid xs={12} lg={6} xl={3}>
        <Stack spacing={3}>
          <Card variant="solid" color="primary" invertedColors>
            <CardContent>
              <Typography level="h5" gutterBottom>
                Status breakdown
              </Typography>
              <ResponsiveContainer width="100%" height={400}>
                <BarChart
                  layout="vertical"
                  data={statusData}
                  margin={{
                    top: 20,
                    right: 88, // Increased right margin to prevent label cutoff
                    left: 0, // Increased left margin to shift graph left
                    bottom: 20,
                  }}
                >
                  <XAxis type="number" hide />
                  <YAxis
                    dataKey="name"
                    type="category"
                    axisLine={false}
                    tick={{ fill: "var(--joy-palette-text-primary)" }}
                    width={88}
                  />
                  <Bar dataKey="value" fill="var(--joy-palette-primary-400)">
                    {statusData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={COLORS[index % COLORS.length]}
                      />
                    ))}
                    <LabelList
                      dataKey="value"
                      position="right"
                      formatter={(value) =>
                        `${value} (${((value / statusData.reduce((acc, curr) => acc + curr.value, 0)) * 100).toFixed(0)}%)`
                      }
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>

          <Card variant="solid" color="primary" invertedColors>
            <CardContent>
              <Typography level="body-md" gutterBottom>
                Languages
              </Typography>
              <ResponsiveContainer width="100%" height={200}>
                <BarChart
                  layout="vertical"
                  data={Object.entries(stats.languageTotals).map(
                    ([lang, count]) => ({
                      name: upperFirstLetterWord(lang),
                      value: count,
                    }),
                  )}
                  margin={{
                    right: 33,
                    left: 55,
                  }}
                >
                  <XAxis type="number" hide />
                  <YAxis
                    dataKey="name"
                    type="category"
                    axisLine={false}
                    tickLine={false}
                    tick={{ fill: "var(--joy-palette-text-primary)" }}
                  />
                  <Bar dataKey="value" fill="var(--joy-palette-primary-300)">
                    <LabelList
                      dataKey="value"
                      position="right"
                      fill="var(--joy-palette-text-primary)"
                    />
                  </Bar>
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
          <Card variant="solid" color="primary" invertedColors>
            <CardContent>
              <Typography level="body-md">
                Total endometriosis surgeries
              </Typography>
              <Typography level="h2" gutterBottom>
                {stats.medicalStats.hadSurgery}
              </Typography>
              <Typography level="body-md">Total semen analyses</Typography>
              <Typography level="h2" gutterBottom>
                {stats.medicalStats.tubalPatency}
              </Typography>
              <Typography level="body-md">
                Total tubal patency tests done
              </Typography>
              <Typography level="h2" gutterBottom>
                {stats.medicalStats.semenAnalysis}
              </Typography>
            </CardContent>
          </Card>
        </Stack>
      </Grid>
      <Grid lg={6} xl={3}>
        <Card variant="solid" color="primary" invertedColors>
          <CardContent>
            <Typography level="body-md">
              Total actively trying to conceive
            </Typography>
            <Typography level="h2" gutterBottom>
              {stats.totalTryingToGetPregnant}
            </Typography>
            <Typography level="body-md">
              Total trying to conceive and had surgery
            </Typography>
            <Typography level="h2" gutterBottom>
              {stats.tyringToConceiveAndHadSurgery}
            </Typography>
            <Typography level="body-md" gutterBottom>
              Assisted Reproductive Technology breakdown
            </Typography>
            <ResponsiveContainer width="100%" height={333}>
              <PieChart>
                <Pie
                  data={artData}
                  cx="50%"
                  cy="50%"
                  labelLine={false}
                  outerRadius={80}
                  fill="#8884d8"
                  dataKey="value"
                  label={({ name, percent }) =>
                    `${name} ${(percent * 100).toFixed(0)}%`
                  }
                >
                  {artData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={COLORS[index % COLORS.length]}
                    />
                  ))}
                </Pie>
                <Tooltip
                  formatter={(value, name, props) => [
                    value,
                    props.payload.name,
                  ]}
                />
              </PieChart>
            </ResponsiveContainer>
            {artData.map((art) => (
              <Box key={art.fullName}>
                <Typography level="body-sm">{art.name}</Typography>
                <Typography level="h2" gutterBottom>
                  {art.value}
                </Typography>
              </Box>
            ))}
          </CardContent>
        </Card>
      </Grid>

      <Grid xs={12}>
        <Typography level="h2" gutterBottom>
          Participants to follow up with
        </Typography>
        <Divider />
      </Grid>

      {/* Inactivity Section */}
      <Grid xs={12} lg={6}>
        <Typography level="title-lg" gutterBottom>
          Survey inactivity
        </Typography>
        <List>
          {Object.keys(participants)
            .filter((participantId) => {
              const participant = participants[participantId];
              return (
                participant.status === "active" &&
                participant.followUp &&
                participant.followUpReason.includes(
                  "months since their last survey",
                )
              );
            })
            .map((participantId) => (
              <ListItem key={participantId}>
                <ListItemButton
                  onClick={() =>
                    navigate(`/dashboard/participants/${participantId}`)
                  }
                >
                  <ListItemDecorator>
                    <Person fontSize="lg" />
                  </ListItemDecorator>
                  <ListItemContent>
                    <Typography level="body-md">
                      {participants[participantId].firstName}{" "}
                      {participants[participantId].lastName}{" "}
                      <Chip>{participants[participantId].redcapId}</Chip>
                    </Typography>
                    <Typography level="body-xs" color="warning">
                      {participants[participantId].followUpReason}
                    </Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Grid>

      {/* Tests/Procedures Section */}
      <Grid xs={12} lg={6}>
        <Typography level="title-lg" gutterBottom>
          Recent tests and procedures
        </Typography>
        <List>
          {Object.keys(participants)
            .filter((participantId) => {
              const participant = participants[participantId];
              return (
                participant.status === "active" &&
                participant.followUp &&
                (participant.followUpReason.includes("tubal patency test") ||
                  participant.followUpReason.includes("semen analysis") ||
                  participant.followUpReason.includes("endometriosis surgery"))
              );
            })
            .map((participantId) => (
              <ListItem key={participantId}>
                <ListItemButton
                  onClick={() =>
                    navigate(`/dashboard/participants/${participantId}`)
                  }
                >
                  <ListItemDecorator>
                    <Person fontSize="lg" />
                  </ListItemDecorator>
                  <ListItemContent>
                    <Typography level="body-md">
                      {participants[participantId].firstName}{" "}
                      {participants[participantId].lastName}{" "}
                      <Chip>{participants[participantId].redcapId}</Chip>
                    </Typography>
                    <Typography level="body-xs" color="warning">
                      {participants[participantId].followUpReason}
                    </Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Grid>

      {/* <Grid xs={12}>
        <Typography level="h2" gutterBottom>
          Participants to follow up with
        </Typography>
        <Divider />
      </Grid>
      <Grid xs={12}>
        <List>
          {Object.keys(participants)
            .filter(participantId => participants[participantId].followUp)
            .map(participantId => (
              <ListItem key={participantId}>
                <ListItemButton
                  onClick={() =>
                    navigate(`/dashboard/participants/${participantId}`)
                  }
                >
                  <ListItemDecorator>
                    <Person fontSize="lg" />
                  </ListItemDecorator>
                  <ListItemContent>
                    <Typography level="body-md">
                      {participants[participantId].firstName}{' '}
                      {participants[participantId].lastName}{' '}
                      <Chip>{participants[participantId].redcapId}</Chip>
                    </Typography>
                    <Typography level="body-xs" color="warning">
                      {participants[participantId].followUpReason}
                    </Typography>
                  </ListItemContent>
                </ListItemButton>
              </ListItem>
            ))}
        </List>
      </Grid> */}
      {/* <Grid lg={6}>
        <Typography level="title-lg" gutterBottom>
          Participants that skipped the last 1 or 2 surveys
        </Typography>
        <List>
          {Object.keys(participants)
            .filter(participantId => {
              const timestamp =
                participants[participantId].netResponses.timestamp;
              if (!timestamp) return false;
              const daysSinceLastResponse = differenceInDays(
                new Date(),
                timestamp.toDate()
              );
              return (
                daysSinceLastResponse > 30 &&
                daysSinceLastResponse < 90 &&
                participants[participantId].status === 'active'
              );
            })
            .map(participantId => participantToFollowUp(participantId))}
        </List>
      </Grid>
      <Grid lg={6}>
        <Typography level="title-lg" gutterBottom>
          Participants that skipped more than 3 surveys
        </Typography>
        <List>
          {Object.keys(participants)
            .filter(participantId => {
              const timestamp =
                participants[participantId].netResponses.timestamp;
              if (!timestamp) return false;
              const daysSinceLastResponse = differenceInDays(
                new Date(),
                timestamp.toDate()
              );
              return (
                daysSinceLastResponse > 90 &&
                participants[participantId].status === 'active'
              );
            })
            .map(participantId => participantToFollowUp(participantId))}
        </List>
      </Grid> */}
    </Grid>
  );
};

export default Overview;
